import React from 'react';
import './src/styles/global.scss';


import { ThemeProvider } from 'emotion-theming';
import theme from './src/theme';

export const wrapPageElement = ({ element }) => {
  return <ThemeProvider {...{theme}}>{element}</ThemeProvider>;
};
